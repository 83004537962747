import React, { useEffect, useState } from 'react'

import {
  Image,
  ImageBackground,
  Linking,
  Platform,
  Pressable,
  ScrollView,
  Share,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Helmet } from 'react-helmet-async';

import { useNavigation, useRoute } from '@react-navigation/native';
import Clipboard from '@react-native-clipboard/clipboard';

import { track } from '@amplitude/analytics-react-native';

import Button from '../../common/components/Button';
import {
  ActiveIngredient,
  InactiveIngredient,
  UnknownIngredient
} from '../../common/components/Ingredient';
import IngredientsList from '../../common/components/IngredientsList';
import ing_styles from '../../common/components/IngredientStyles';
import { Picker } from '../../common/components/Picker';

import common_styles, { borderRadius, fullWidth, padding } from '../../common/components/CommonStyles';

import Bookmark from '../../assets/Bookmark';
import Checkmark from '../../assets/Checkmark';
import Copy from '../../assets/Copy';
import Edit from '../../assets/Edit';
import ExternalLink from '../../assets/ExternalLink';
import MealPlan from '../../assets/MealPlan';
import PaperPlane from '../../assets/PaperPlane';
import RecipeImagePlaceholder from '../../assets/RecipeImagePlaceholder';
import User from '../../assets/User';

import {
  arraysEqual,
  Body1,
  Body2,
  ButtonText,
  CloseButton,
  getEmojisFromRecipe,
  H1,
  H2Sans,
  PlusButton,
  RoundButton,
  sleep,
  Small,
  SmallUppercase,
  Title,
  useAppWidth,
  VSkipLarge,
  VSkipMedium,
  VSkipSmall,
  XSmall
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import {
  APP_TABS,
  COLORS,
  FEED_SCREENS,
  HOUSEHOLD_SCREENS,
  MODAL_SCREENS,
  RECIPE_PATH,
  ROOT_SCREENS,
  USER_PATH,
  USER_SCREENS
} from '../../common/constants/';
import {
  BASE_URL,
  MEDIA_PREFIX
} from '../../common/constants/urls';

const getAmountString = (t, ingredient) => {
  let amount_str = '';
  if (!ingredient.amount_str && !ingredient.unit) {
    amount_str = null;
  } else if (!ingredient.amount_str) {
    amount_str = t(`units.${ingredient.unit}`, {count: ingredient.amount})
  } else if (!ingredient.unit) {
    amount_str = ingredient.amount_str;
  } else {
    amount_str = ingredient.amount_str + ' ' + t(`units.${ingredient.unit}`, {count: ingredient.amount})
  }

  return amount_str;
}

const Ingredient = ({ingredient, addIngredient, deleteIngredient}) => {
  const theme = useTheme();

  const { t } = useTranslation(['recipes']);
  const amount_str = getAmountString(t, ingredient);

  return (
    <>
      <TouchableOpacity
        style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}
        onPress={ingredient.is_active ? deleteIngredient : addIngredient}
      >
        <Body2 style={[theme.secondary, {flex: 1.5}]}>
          {
            amount_str
          }
        </Body2>
        <Body2 style={[theme.secondary, common_styles.centeredText, {flex: 1}]}>
          {
            ingredient.ingredient?.emoji
          }
        </Body2>
        <Body2 style={[theme.secondary, {flex: 4}]}>
          { ingredient.ingredient ? ingredient.ingredient.name : ingredient.original_string_wo_quantity }
        </Body2>
        {
          ingredient.is_active ? (
            <View style={[ing_styles.dot, ing_styles.greenBg]}>
              <Checkmark color={theme.activeIngredient.color}/>
            </View>
          ) : (
            <View style={[ing_styles.dot, ing_styles.dottedOutline]}/>
          )
        }
      </TouchableOpacity>
      <VSkipSmall/>
    </>
  )
}

const RecipeDetail = ({
  recipe,
  query,
  initial_open,
  always_active_ingredients,
  active_ingredients,
  inactive_ingredients,
  logged_in,
  user_uuid,
  addBookmark,
  deleteBookmark,
  loadRecipe,
  addActiveIngredient,
  addInactiveIngredient,
  addMealPlanEntry,
  deleteMealPlanEntry
}) => {
  const width = useAppWidth();

  const [card_width, setCardWidth] = useState(width);
  const [navigation_origin, setNavigationOrigin] = useState(undefined);
  const [ show_share_action_picker, setShowShareActionPicker ] = useState(false);

  const navigation = useNavigation();
  const route = useRoute();

  const { t } = useTranslation(['recipes', 'common']);

  const { bottom, top } = useSafeAreaInsets();

  const theme = useTheme();

  const always_active_ingredient_ids = always_active_ingredients.map(ing => ing.id);

  useEffect(() => {
    if (recipe && logged_in) {
      const all_active_ingredient_ids = [...new Set([...active_ingredients.map(ing => ing.id) , ...always_active_ingredient_ids])];
      if (!arraysEqual(query.active_ingredients, all_active_ingredient_ids) ||
          !arraysEqual(query.inactive_ingredients, inactive_ingredients.map(ingredient => ingredient.id))) {
        loadRecipe(recipe.uuid);
      }
    }
  }, [recipe, query, always_active_ingredient_ids, active_ingredients, inactive_ingredients, loadRecipe]);

  useEffect(() => {
    if (route.params && route.params.uuid) {
      loadRecipe(route.params.uuid);
    }
    if (route.params && route.params.nav_origin) {
      setNavigationOrigin(route.params.nav_origin);
    } else {
      setNavigationOrigin(undefined);
    }
  }, [route.params]);

  if (!recipe) {
    return null;
  } else {
    const active_ingredients = recipe.active_ingredients.map(ing_w_props => {
      return {...ing_w_props, ...ing_w_props.ingredient};
    });
    const inactive_ingredients = recipe.inactive_ingredients.map(ing_w_props => {
      return {...ing_w_props, ...ing_w_props.ingredient};
    });
    const unknown_ingredients = recipe.unknown_ingredients.map(ing_w_props => {
      return {...ing_w_props, ...ing_w_props.ingredient};
    });

    const name = recipe.instance.name;
    const url = recipe.instance.url;

    const emojis = getEmojisFromRecipe(recipe);

    const share_url = `${BASE_URL}/${ROOT_SCREENS.app.path}/${RECIPE_PATH}/${recipe.uuid}/`;
    const title = t('shareRecipeTitle');
    const message = t('shareRecipeText', {link: share_url});
    const share_content = {
      title,
      message
    };
    const share_options = {
      dialogTitle: title,
      subject: title,
      tintColor: theme.general.color
    };

    let schema_data = {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      "name": name
    };

    let Header = null;
    if (recipe.images.length) {
      const {image, image_placeholder} = recipe.images[0];
      const image_width = recipe.images[0].width;
      const image_height = recipe.images[0].height;
      const image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};

      schema_data = {
        ...schema_data,
        "image": [
          image
        ]
      };

      Header = ({children, blur, ...props}) => (
        <Pressable
          onPress={() => url ? Linking.openURL(url) : null}
        >
          <ImageBackground
            defaultSource={image_placeholder_uri}
            source={blur ? null : {uri: `${MEDIA_PREFIX}${image}`}}
            style={[
              {width: card_width + 1, height: image_width ? image_height / image_width * (card_width + 1) : 0},
              styles.image
            ]}
          >
            { children }
          </ImageBackground>
        </Pressable>
      );
    } else {
      Header = ({children, ...props}) => (
        <Pressable
          onPress={() => url ? Linking.openURL(url) : null}
          style={{width: card_width + 1, height: card_width + 1}}
        >
          <RecipeImagePlaceholder
            emoji={emojis[0]}
          />
          { children }
        </Pressable>
      );
    }

    let avatar_image = '';
    let avatar_image_placeholder_uri = '';
    let author_action = undefined;

    if (recipe.author && recipe.author.userprofile.image && recipe.author.userprofile.image) {
      schema_data = {
        ...schema_data,
        "author": {
          "@type": "Person",
          "name": recipe.author.username,
          "url": `${BASE_URL}/${ROOT_SCREENS.app.path}/${USER_PATH}/${recipe.author.username}/`
        }
      };
      avatar_image = recipe.author.userprofile.image.image_50;
      const avatar_image_placeholder = recipe.author.userprofile.image.image_placeholder;
      avatar_image_placeholder_uri = {uri: `data:image/png;base64,${avatar_image_placeholder}`};
      author_action = () => navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name, params: {username: recipe.author.username}});
    } else if (recipe.source && recipe.source.logo) {
      schema_data = {
        ...schema_data,
        "author": {
          "@type": "Organization",
          "name": recipe.source.name,
          "url": recipe.source.url
        }
      };
      avatar_image = recipe.source.logo;
      author_action = () => Linking.openURL(recipe.source.url);
    }

    if (recipe.instance.notes) {
      schema_data = {
        ...schema_data,
        "recipeInstructions": recipe.instance.notes
      };
    }

    if (recipe.ingredients_with_properties.length) {
      schema_data = {
        ...schema_data,
        "recipeIngredient": recipe.ingredients_with_properties.map(ing => {
          const ing_str = ing.ingredient ? ing.ingredient.name : ing.original_string_wo_quantity;
          const amount_str = getAmountString(t, ing);
          if (amount_str) return `${amount_str} ${ing_str}`;
          else return ing_str;
        })
      };
    }

    return (
      <>
        <Helmet>
          { recipe.source ? <title>{ t('common:app.metaTags.recipeDetail.titleWSource', { title: name, source: recipe.source.name}) }</title> : null }
          { recipe.source ? <meta property="og:title" content={t('common:app.metaTags.recipeDetail.titleWSource', { title: name, source: recipe.source.name})} /> : null }
          { recipe.author ? <title>{ t('common:app.metaTags.recipeDetail.titleWAuthor', { title: name, author: recipe.author.username}) }</title> : null }
          { recipe.author ? <meta property="og:title" content={t('common:app.metaTags.recipeDetail.titleWAuthor', { title: name, author: recipe.author.username})} /> : null }
          { recipe.images.length ? <meta property="og:image" content={`${MEDIA_PREFIX}${recipe.images[0].image}`} /> : null }
          { recipe.images.length ? <meta property="og:height" content={recipe.images[0].height} /> : null }
          { recipe.images.length ? <meta property="og:width" content={recipe.images[0].width} /> : null }
          <meta property="og:description" content={t('common:app.metaTags.recipeDetail.description')} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={share_url} />
          <script type="application/ld+json">{ JSON.stringify(schema_data) }</script>
        </Helmet>
        <Header blur/>
        <ScrollView style={StyleSheet.absoluteFill}>
          <View
            style={theme.general}
            onLayout={(event) => {
              const { width } = event.nativeEvent.layout;
              setCardWidth(width);
            }}
          >
            <Header>
              <CloseButton
                onPress={() => {
                  if (navigation_origin && navigation_origin.name) {
                    navigation.navigate(navigation_origin.name)
                  } else {
                    navigation.goBack()
                  }
                }}
                style={{position: 'absolute', top: 15, right: 15}}
              />
            </Header>
            <View style={fullWidth}>
              <H1 style={{...theme.general, padding}}>
                { name }
              </H1>

              <TouchableOpacity
                style={{padding, flexDirection: 'row', alignItems: 'center'}}
                onPress={author_action}
              >

                {
                  avatar_image ? (
                    <Image
                      style={common_styles.roundButton}
                      defaultSource={avatar_image_placeholder_uri}
                      source={{uri: `${MEDIA_PREFIX}${avatar_image}`}}
                    />
                  ) : (
                    <RoundButton
                      style={theme.secondary}
                    >
                      <User color={theme.general.color}/>
                    </RoundButton>
                  )
                }

                {
                  recipe.source ?
                    <Body2 style={{...theme.general, paddingHorizontal: padding}}>
                      &copy;{ recipe.source.name }
                    </Body2> : null
                }
                {
                  recipe.author ?
                    <Body2 style={{...theme.general, paddingHorizontal: padding}}>
                      { `${t(`recipeType.${recipe.recipe_type}`)} ${t('byAuthor')} ${recipe.author.username}` }
                    </Body2> : null
                }

              </TouchableOpacity>

              <View style={styles.actionsContainer}>
                {
                  (!recipe.have_bookmarked) ? (
                    <TouchableOpacity
                      style={styles.action}
                      onPress={() => {
                        if (!logged_in) {
                          navigation.navigate(APP_TABS.feed.name)
                        } else {
                          addBookmark(recipe.uuid);
                          loadRecipe(recipe.uuid);
                        }
                      }}
                    >
                      <View style={common_styles.roundButton}>
                        <View style={common_styles.roundButtonIcon}>
                          <Bookmark color={theme.general.color} filled={recipe.have_bookmarked}/>
                        </View>
                      </View>
                      <View style={styles.actionLabelContainer}>
                        <Body2 style={[theme.general, styles.actionLabel]}>
                          { t('addBookmark') }
                        </Body2>
                      </View>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.action}
                      onPress={() => {
                        if (!logged_in) {
                          navigation.navigate(APP_TABS.feed.name)
                        } else {
                          deleteBookmark(recipe.uuid);
                          loadRecipe(recipe.uuid);
                        }
                      }}
                    >
                      <View style={common_styles.roundButton}>
                        <View style={common_styles.roundButtonIcon}>
                          <Bookmark color={theme.general.color} filled={recipe.have_bookmarked}/>
                        </View>
                      </View>
                      <View style={styles.actionLabelContainer}>
                        <Body2 style={[theme.general, styles.actionLabel]}>
                          { t('removeBookmark') }
                        </Body2>
                      </View>
                    </TouchableOpacity>
                  )
                }
                {
                  (recipe.planned_meals.length === 0) ? (
                    <TouchableOpacity
                      style={styles.action}
                      onPress={() => {
                        if (!logged_in) {
                          navigation.navigate(APP_TABS.feed.name)
                        } else {
                          addMealPlanEntry(
                            [recipe],
                            /*success_callback*/() => {},
                            /*failure_callback*/() => {},
                            /*final_callback*/() => loadRecipe(recipe.uuid));
                        }
                      }}
                    >
                      <View style={common_styles.roundButton}>
                        <View style={common_styles.roundButtonIcon}>
                          <MealPlan color={theme.general.color}/>
                        </View>
                      </View>
                      <View style={styles.actionLabelContainer}>
                        <Body2 style={[theme.general, styles.actionLabel]}>
                          { t('addToMealPlan') }
                        </Body2>
                      </View>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.action}
                      onPress={() => {
                        if (!logged_in) {
                          navigation.navigate(APP_TABS.feed.name)
                        } else {
                          recipe.planned_meals.forEach(planned_meal => deleteMealPlanEntry(planned_meal.uuid));
                          loadRecipe(recipe.uuid);
                        }
                      }}
                    >
                      <View style={common_styles.roundButton}>
                        <View style={common_styles.roundButtonIcon}>
                          <MealPlan color={theme.general.color} filled={true}/>
                        </View>
                      </View>
                      <View style={styles.actionLabelContainer}>
                        <Body2 style={[theme.general, styles.actionLabel]}>
                          { t('removeFromMealPlan') }
                        </Body2>
                      </View>
                    </TouchableOpacity>
                  )
                }
                <TouchableOpacity
                  style={styles.action}
                  onPress={() => {
                    navigation.navigate(
                      APP_TABS.feed.name,
                      {
                        screen: FEED_SCREENS.postMake.name,
                        params: {
                          recipes: [recipe]
                        }
                      }
                    );
                  }}
                >
                  <View style={common_styles.roundButton}>
                    <View style={common_styles.roundButtonIcon}>
                      <PlusButton disabled/>
                    </View>
                  </View>
                  <View style={styles.actionLabelContainer}>
                    <Body2 style={[theme.general, styles.actionLabel]}>
                      { t('postMake') }
                    </Body2>
                  </View>
                </TouchableOpacity>
                {
                  Platform.select({
                    web: (
                      <TouchableOpacity
                        style={styles.action}
                        onPress={
                          () => {
                            track('copy_recipe_link', {
                              category: 'recipe_detail'
                            });
                            Clipboard.setString(message);
                          }
                        }
                      >
                        <View style={common_styles.roundButton}>
                          <View style={common_styles.roundButtonIcon}>
                            <Copy color={theme.general.color}/>
                          </View>
                        </View>
                        <View style={styles.actionLabelContainer}>
                          <Body2 style={[theme.general, styles.actionLabel]}>
                            { t('copyRecipeLink') }
                          </Body2>
                        </View>
                      </TouchableOpacity>
                    ),
                    default: (
                      <TouchableOpacity
                        style={styles.action}
                        onPress={() => setShowShareActionPicker(true)}
                      >
                        <View style={common_styles.roundButton}>
                          <View style={common_styles.roundButtonIcon}>
                            <PaperPlane color={theme.general.color}/>
                          </View>
                        </View>
                        <View style={styles.actionLabelContainer}>
                          <Body2 style={[theme.general, styles.actionLabel]}>
                            { t('shareRecipe') }
                          </Body2>
                        </View>
                      </TouchableOpacity>
                    )
                  })
                }
                {
                  (recipe.author && (recipe.author.userprofile.uuid === user_uuid)) ? (
                    <TouchableOpacity
                      style={styles.action}
                      onPress={() => {
                        if (!logged_in) {
                          navigation.navigate(APP_TABS.feed.name)
                        } else {
                          track('edit_recipe', {
                            category: 'recipe_detail',
                            name: recipe.instance.name
                          });
                          navigation.navigate(
                            APP_TABS.household.name,
                            {
                              screen: HOUSEHOLD_SCREENS.recipeEditor.name,
                              params: {
                                recipe
                              }
                            }
                          );
                        }
                      }}
                    >
                      <View style={common_styles.roundButton}>
                        <View style={common_styles.roundButtonIcon}>
                          <Edit color={theme.general.color} filled={recipe.have_bookmarked}/>
                        </View>
                      </View>
                      <View style={styles.actionLabelContainer}>
                        <Body2 style={[theme.general, styles.actionLabel]}>
                          { t('editRecipe') }
                        </Body2>
                      </View>
                    </TouchableOpacity>
                  ) : null
                }
              </View>

              <View
                style={{
                  height: StyleSheet.hairlineWidth,
                  backgroundColor: COLORS.LIGHT_GRAY,
                  margin: padding
                }}
              />
                <View style={{padding}}>
                  <View style={[theme.secondary, common_styles.card]}>
                    <SmallUppercase style={[theme.secondary, {opacity: 1.0}]}>
                      {t('ingredientsTitle')}
                    </SmallUppercase>
                    <VSkipLarge/>
                    <View>
                      {
                        recipe.ingredients_with_properties.length ? (
                          <>
                            <View style={{flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
                              <SmallUppercase style={theme.general}>
                                { t('inYourPantry') }
                              </SmallUppercase>
                            </View>
                            <VSkipLarge/>
                          </>
                        ) : null
                      }
                      {
                        recipe.ingredients_with_properties.map((ingredient, index) => (
                          <Ingredient
                            ingredient={ingredient}
                            key={index}
                            addIngredient={() => addActiveIngredient(ingredient.ingredient)}
                            deleteIngredient={() => addInactiveIngredient(ingredient.ingredient)}
                          />
                        ))
                      }
                      <VSkipLarge/>
                    </View>
                  </View>

                  <VSkipLarge/>

                  {
                    url ? (
                      <Button
                        style={[theme.button, {justifyContent: 'space-between'}]}
                        onPress={() => Linking.openURL(url)}
                      >
                        <ButtonText style={theme.button}>
                          { t('openRecipeButton') }
                        </ButtonText>
                        <View style={styles.buttonIcon}>
                          <ExternalLink color={theme.button.color} />
                        </View>
                      </Button>
                    ) : (
                      <View style={{paddingVertical: padding}}>
                        <SmallUppercase style={[theme.general, {opacity: 1.0}]}>
                          {t('instructionsTitle')}
                        </SmallUppercase>
                        <VSkipMedium/>
                        {
                          recipe.instance.notes ? (
                            <Body1 style={theme.general}>{recipe.instance.notes}</Body1>
                          ) : ( // TODO add hint if url -> visit url
                            <Body1 style={theme.general}>{t('noInstructions')}</Body1>
                          )
                        }
                      </View>
                    )
                  }
              </View>
            </View>
          </View>
        </ScrollView>
        <Picker
          options={[
            {
              label: t('shareRecipe'),
              action: () => {
                sleep(100).then(
                  async () => {
                    track('recipe_share_started', {
                      category: 'recipe_detail'
                    });
                    const result = await Share.share(share_content, share_options);
                    if (result.action === Share.sharedAction) {
                      console.log('shared with activityType', result.activityType);
                      track('recipe_share_completed', {
                        category: 'recipe_detail'
                      });
                    } else if (result.action === Share.dismissedAction) {
                      console.log('share dismissed', result);
                      track('recipe_share_aborted', {
                        category: 'recipe_detail'
                      });
                    }
                  }
                )
              }
            },
            {
              label: t('common:feed.shareStory'),
              action: () => {
                if (Platform.OS === 'web') {
                  pushNotification(
                    NotificationSeverity.WARNING,
                    t('feed.shareStoryNotAvailable'),
                    3
                  );
                } else {
                  navigation.navigate(
                    MODAL_SCREENS.storyShare.name,
                    {
                      image: recipe.images[0]
                    }
                  )
                }
              },
              disabled: (recipe.images.length === 0)
            }
          ]}
          visible={show_share_action_picker}
          onRequestClose={() => setShowShareActionPicker(false)}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  actionsContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: 0.5 * padding
  },
  action: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actionLabelContainer: {
    textAlign: 'center',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 0.5 * padding
  },
  actionLabel: {
    textAlign: 'center'
  },
  buttonIcon: {
    height: 15,
    width: 15
  },
  image: {
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius
  }
});

export default RecipeDetail;
