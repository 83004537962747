import React from 'react';

const Video = ({source, children, ...props}) => (
  <>
    <video
      height="100%"
      width="auto"
      autoPlay={true}
      loop={true}
      {...props}
    >
      <source
        src={source}
        type="video/mp4"
      />
    </video>
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      }}
    >
      { children }
    </div>
  </>
);

export default Video;
