import React, { useEffect, useRef, useState } from 'react';
import {
  ImageBackground,
  Keyboard,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions
} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import {
  useIsFocused,
  useNavigation
} from '@react-navigation/native';

import { track } from '@amplitude/analytics-react-native';

import LoginSignUp from '../../auth/containers/LoginSignUpContainer';
import { Mode as LoginSignUpMode } from '../../auth/components/LoginSignUp';
import Button from '../../common/components/Button';
import { ArrowLeft } from '../../assets/Arrow';

import {
  BackButton,
  Body1,
  Body2,
  ButtonText,
  H1,
  TitleHandwritten,
  H2Serif,
  PageIndicator,
  PAGE_INDICATOR_HEIGHT,
  shuffleArray,
  XSmall,
  Title,
  useAppWidth,
  VSkipHuge,
  VSkipLarge,
  VSkipMedium
} from '../../utils/';
import {
  DarkTheme,
  LightTheme,
  useTheme
} from '../../utils/Theme';
import Video from '../../utils/Video';
import {
  APP_TABS,
  COLORS,
  DIETS,
  MISC_SCREENS
} from '../../common/constants/';

import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18next';

import common_styles, {
  bigBorderRadius,
  padding,
  text_styles_raw
} from '../../common/components/CommonStyles';

import OnboardingFirstPageVideo from '../../assets/grapes.mp4';
import OnboardingLastPageVideo from '../../assets/orange.mp4';
import LinearGradient from '../../assets/LinearGradient';
import Strike from '../../assets/Strike01';

export const NEXT_BUTTON_MODE = Object.freeze({
  "NEXT": 0,
  "SUBMIT": 1
});


const QuestionPage = ({
  children,
  index,
  onBack,
  onNext,
  next_button_mode = NEXT_BUTTON_MODE.NEXT
}) => {
  const { t } = useTranslation('common');

  const theme = useTheme();

  const { bottom, top } = useSafeAreaInsets();

  return (
    <ScrollView
      style={{flex: 1}}
      contentContainerStyle={{
        padding,
        paddingTop: top,
        paddingBottom: bottom + PAGE_INDICATOR_HEIGHT + padding
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        {
          (index === 0) ? (
            <BackButton style={{opacity: 0}} disabled/>
          ) : (
            <BackButton
              style={theme.secondary}
              onPress={onBack}
            />
          )
        }
      </View>

      { children }

      <View style={common_styles.buttonContainer}>
        <Button
          style={[common_styles.button, theme.button, {flex: 1}]}
          onPress={onNext}
        >
          <ButtonText style={theme.button}>
            {
              (next_button_mode === NEXT_BUTTON_MODE.NEXT) ?
                t('common.next') : t('common.save')
            }
          </ButtonText>
        </Button>
      </View>
    </ScrollView>
  );
};

const DietPage = ({
  index,
  onBack,
  onNext,
  diet,
  setDiet
}) => {
  const { t } = useTranslation(['welcome', 'common']);

  const theme = useTheme();

  return (
    <QuestionPage index={index} onBack={onBack} onNext={onNext}>
      <View style={{flex:1}}>
        <H1 style={[theme.general, common_styles.centeredText, {padding: 2 * padding}]}>
          { t('dietPage.question') }
        </H1>

        <View>
          {
            Object.values(DIETS).map((diet_candidate, index) => (
              <Button
                key={index}
                onPress={() => setDiet(diet_candidate)}
                style={[
                  (diet === diet_candidate) ? theme.cardSelected : theme.card,
                  common_styles.textField,
                  {
                    shadowOpacity: 0
                  }
                ]}
              >
                <Body1 style={[theme.cardBody, common_styles.centeredText]}>
                  { t(`common:common.diets.${diet_candidate}`) }
                </Body1>
              </Button>
            ))
          }
        </View>
      </View>
      <VSkipLarge/>

      <XSmall style={[theme.general, common_styles.centeredText, {fontSize: text_styles_raw.small.fontSize}]}>{ t('dietPage.hint') }</XSmall>
      <VSkipLarge/>

      {
        ((diet === DIETS.FLEXITARIAN) || (diet === DIETS.PESCETARIAN)) ? <>
          <XSmall style={[theme.general, common_styles.centeredText, {fontSize: text_styles_raw.small.fontSize}]}>{ t('dietPage.hint2') }</XSmall>
          <VSkipLarge/>
        </> : null
      }
    </QuestionPage>
  );
};

const GoalsPage = ({
  diet,
  index,
  onBack,
  onNext,
  meal_planning, setMealPlanning,
  ingredient_mgmt, setIngredientMgmt,
  recipe_mgmt, setRecipeMgmt,
  recipe_sharing, setRecipeSharing,
  recipe_inspo, setRecipeInspo,
  food_waste_reduction, setFoodWasteReduction,
  eat_less_meat, setEatLessMeat,
  eat_less_animal_products, setEatLessAnimalProducts,
  seasonal, setSeasonal
}) => {
  const [ order, setOrder ] = useState([]);

  const { t } = useTranslation(['welcome', 'common']);

  const theme = useTheme();

  const options = [
    {
      enabled: true,
      i18n_suffix: 'mealPlanning',
      selected: meal_planning,
      setSelected: setMealPlanning
    },
    {
      enabled: true,
      i18n_suffix: 'ingredientMgmt',
      selected: ingredient_mgmt,
      setSelected: setIngredientMgmt
    },
    {
      enabled: true,
      i18n_suffix: 'recipeMgmt',
      selected: recipe_mgmt,
      setSelected: setRecipeMgmt
    },
    {
      enabled: true,
      i18n_suffix: 'recipeSharing',
      selected: recipe_sharing,
      setSelected: setRecipeSharing
    },
    {
      enabled: true,
      i18n_suffix: 'recipeInspo',
      selected: recipe_inspo,
      setSelected: setRecipeInspo
    },
    {
      enabled: true,
      i18n_suffix: 'foodWasteReduction',
      selected: food_waste_reduction,
      setSelected: setFoodWasteReduction
    },
    {
      enabled: (diet === DIETS.FLEXITARIAN),
      i18n_suffix: 'eatLessMeat',
      selected: eat_less_meat,
      setSelected: setEatLessMeat
    },
    {
      enabled: (diet !== DIETS.VEGAN),
      i18n_suffix: 'eatLessAnimalProducts',
      selected: eat_less_animal_products,
      setSelected: setEatLessAnimalProducts
    },
    {
      enabled: true,
      i18n_suffix: 'seasonal',
      selected: seasonal,
      setSelected: setSeasonal
    },
  ];

  useEffect(() => {
    if (order.length !== options.length) {
      let new_order = [...Array(options.length).keys()].map(i => i);
      new_order = shuffleArray(new_order);
      setOrder(new_order);
    }
  }, [order, options]);

  return (
    <QuestionPage index={index} onBack={onBack} onNext={onNext}>
      <View style={{flex:1}}>
        <H1 style={[theme.general, common_styles.centeredText, {padding: 2 * padding}]}>
          { t('goalsPage.question') }
        </H1>

        <XSmall style={[theme.general, common_styles.centeredText, {fontSize: text_styles_raw.small.fontSize}]}>{ t('goalsPage.selectMultipleHint') }</XSmall>
        <VSkipLarge/>

        <View>
          {
            order.map(
              (idx) => options[idx]
            ).filter(
              ({enabled}) => enabled
            ).map(
              ({enabled, i18n_suffix, selected, setSelected}, index) => (
              <Button
                key={index}
                onPress={() => setSelected(!selected)}
                style={[
                  selected ? theme.cardSelected : theme.card,
                  common_styles.textField,
                  {
                    shadowOpacity: 0
                  }
                ]}
              >
                <Body1 style={[theme.cardBody, common_styles.centeredText]}>
                  { t(`goalsPage.options.${i18n_suffix}`) }
                </Body1>
              </Button>
            ))
          }
        </View>
      </View>
      <VSkipLarge/>

      <XSmall style={[theme.general, common_styles.centeredText, {fontSize: text_styles_raw.small.fontSize}]}>{ t('goalsPage.hint') }</XSmall>
      <VSkipLarge/>

    </QuestionPage>
  );
};

const RecipeInspoPage = ({
  diet,
  index,
  onBack, onNext,
  next_button_mode = NEXT_BUTTON_MODE.NEXT,
  cheap_meals, setCheapMeals,
  healthy_meals, setHealthyMeals,
  meal_prep, setMealPrep,
  seasonal_recipes, setSeasonalRecipes,
  diversity, setDiversity,
  vegetarian_meals, setVegetarianMeals,
  vegan_meals, setVeganMeals
}) => {
  const [ order, setOrder ] = useState([]);

  const { t } = useTranslation(['welcome', 'common']);

  const theme = useTheme();

  const options = [
    {
      enabled: true,
      i18n_suffix: 'cheapMeals',
      selected: cheap_meals,
      setSelected: setCheapMeals
    },
    {
      enabled: true,
      i18n_suffix: 'healthyMeals',
      selected: healthy_meals,
      setSelected: setHealthyMeals
    },
    {
      enabled: true,
      i18n_suffix: 'mealPrep',
      selected: meal_prep,
      setSelected: setMealPrep
    },
    {
      enabled: true,
      i18n_suffix: 'seasonalRecipes',
      selected: seasonal_recipes,
      setSelected: setSeasonalRecipes
    },
    {
      enabled: true,
      i18n_suffix: 'diversity',
      selected: diversity,
      setSelected: setDiversity
    },
    {
      enabled: (diet !== DIETS.VEGETARIAN && diet !== DIETS.VEGAN),
      i18n_suffix: 'vegetarianMeals',
      selected: vegetarian_meals,
      setSelected: setVegetarianMeals
    },
    {
      enabled: (diet !== DIETS.VEGAN),
      i18n_suffix: 'veganMeals',
      selected: vegan_meals,
      setSelected: setVeganMeals
    }
  ];

  useEffect(() => {
    if (order.length !== options.length) {
      let new_order = [...Array(options.length).keys()].map(i => i);
      new_order = shuffleArray(new_order);
      setOrder(new_order);
    }
  }, [order, options]);

  return (
    <QuestionPage index={index} onBack={onBack} onNext={onNext} next_button_mode={next_button_mode}>
      <View style={{flex:1}}>

        <H1 style={[theme.general, common_styles.centeredText, {padding: 2 * padding}]}>
          { t('recipeInspoPage.question') }
        </H1>

        <XSmall style={[theme.general, common_styles.centeredText, {fontSize: text_styles_raw.small.fontSize}]}>{ t('goalsPage.selectMultipleHint') }</XSmall>
        <VSkipLarge/>

        <View>
          {
            order.map(
              (idx) => options[idx]
            ).filter(
              ({enabled}) => enabled
            ).map(
              ({enabled, i18n_suffix, selected, setSelected}, index) => (
                <Button
                  key={index}
                  onPress={() => setSelected(!selected)}
                  style={[
                    selected ? theme.cardSelected : theme.card,
                    common_styles.textField,
                    {
                      shadowOpacity: 0
                    }
                  ]}
                >
                  <Body1 style={[theme.cardBody, common_styles.centeredText]}>
                    { t(`recipeInspoPage.options.${i18n_suffix}`) }
                  </Body1>
                </Button>
              )
            )
          }
        </View>
      </View>
      <VSkipLarge/>

      <XSmall style={[theme.general, common_styles.centeredText, {fontSize: text_styles_raw.small.fontSize}]}>{ t('recipeInspoPage.hint') }</XSmall>
      <VSkipLarge/>
    </QuestionPage>
  );
};

const AuthPage = ({onBack, ...props}) => {
  const theme = useTheme();

  const { bottom, top } = useSafeAreaInsets();

  return (
    <ScrollView contentContainerStyle={{
      padding,
      paddingTop: top,
      paddingBottom: padding + PAGE_INDICATOR_HEIGHT + bottom
    }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <BackButton
          style={theme.secondary}
          onPress={onBack}
        />
      </View>
      <LoginSignUp
        {...props}
      />
    </ScrollView>
  );
};

const TitleHandwrittenRotated = ({children}) => {
  const theme = useTheme();

  return (
    <View style={{transform: [{rotate: '-6.3deg'}]}}>
      <TitleHandwritten style={{color: COLORS.PURPLE}}>
        { children }
      </TitleHandwritten>
    </View>
  )
};

const TitleStrikeThrough = ({children}) => {
  const theme = useTheme();

  return (
    <View>
      <Title style={{...LightTheme.general, backgroundColor: null}}>
        { children }
      </Title>
      <View
        style={{
          ...StyleSheet.absoluteFill,
          left: -5,
          right: -5,
          bottom: -5,
          transform: [{rotate: '6.3deg'}]
        }}
      >
        <Strike color={COLORS.PURPLE} stroke_width={6.3}/>
      </View>
    </View>
  )
};

export const Mode = Object.freeze({
  "NEW_USER": 0,
  "EXISTING_USER": 1
})

const Onboarding = ({
  mode = Mode.NEW_USER,
  postOnboardingAnswers,
  visible,
  onComplete
}) => {
  const [ active_elem, setActiveElem ] = useState(0);
  const [ auth_mode, setAuthMode ] = useState(LoginSignUpMode.SIGN_UP);

  const [ diet, setDiet ] = useState(DIETS.FLEXITARIAN);

  const [ meal_planning, setMealPlanning ] = useState(false);
  const [ ingredient_mgmt, setIngredientMgmt ] = useState(false);
  const [ recipe_mgmt, setRecipeMgmt ] = useState(false);
  const [ recipe_sharing, setRecipeSharing ] = useState(false);
  const [ recipe_inspo, setRecipeInspo ] = useState(false);
  const [ food_waste_reduction, setFoodWasteReduction ] = useState(false);
  const [ eat_less_meat, setEatLessMeat ] = useState(false);
  const [ eat_less_animal_products, setEatLessAnimalProducts ] = useState(false);
  const [ seasonal, setSeasonal ] = useState(false);

  const [ cheap_meals, setCheapMeals ] = useState(false);
  const [ healthy_meals, setHealthyMeals ] = useState(false);
  const [ meal_prep, setMealPrep ] = useState(false);
  const [ seasonal_recipes, setSeasonalRecipes ] = useState(false);
  const [ diversity, setDiversity ] = useState(false);
  const [ vegetarian_meals, setVegetarianMeals ] = useState(false);
  const [ vegan_meals, setVeganMeals ] = useState(false);

  const { t } = useTranslation(['welcome', 'common']);

  const navigation = useNavigation();

  const theme = useTheme();

  const scroll_ref = useRef();

  const screen_is_focused = useIsFocused();

  const win_width = useWindowDimensions().width;
  const width = useAppWidth();
  const { bottom, top } = useSafeAreaInsets();

  const animated = (Platform.OS !== 'web');  // Setting animated to true breaks 'scrollTo' on (iOS) Safari (in combination with scrollEnabled=false)

  useEffect(() => {
    if (screen_is_focused && visible) {
      Keyboard.dismiss();
      scroll_ref.current?.scrollTo({x: 0, animated});
      setActiveElem(0);
      setDiet(DIETS.FLEXITARIAN);
      setMealPlanning(false);
      setIngredientMgmt(false);
      setRecipeMgmt(false);
      setRecipeSharing(false);
      setRecipeInspo(false);
      setFoodWasteReduction(false);
      setEatLessMeat(false);
      setEatLessAnimalProducts(false);
      setSeasonal(false);
      setCheapMeals(false);
      setHealthyMeals(false);
      setMealPrep(false);
      setSeasonalRecipes(false);
      setDiversity(false);
      setVegetarianMeals(false);
      setVeganMeals(false);
    }
  }, [screen_is_focused, visible]);

  const num_pages = (mode === Mode.NEW_USER) ? 6 : 3;

  const skip_onboarding_button = (
    <Button
      style={LightTheme.secondaryButton}
      onPress={() => {
        setAuthMode(LoginSignUpMode.LOGIN);
        track('skip_onboarding', {
          category: 'onboarding',
          value: active_elem
        });
        scroll_ref.current?.scrollTo({x: (num_pages - 2) * win_width, animated});
      }}
    >
      <ButtonText
        style={{...LightTheme.secondaryButton, backgroundColor: null}}
      >
        <Trans i18nKey={'common:auth.logInButtonText'}>
          You've got an account? <ButtonText style={{textDecorationLine: 'underline'}}>Log in</ButtonText>
        </Trans>
      </ButtonText>
    </Button>
  );

  const OnboardingPage = ({children, video, index, style}) => {
    const { t } = useTranslation('welcome');
    const theme = useTheme();

    const header = (
      <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
        { (index === 0) ?
          (
            <BackButton style={{opacity: 0}} disabled/>
          ) : (
            <BackButton onPress={() => scroll_ref.current?.scrollTo({x: (active_elem - 1) * win_width, animated})}/>
          )
        }
      </View>
    );

    return (
      <Video
        source={video}
        style={{...StyleSheet.absoluteFill, ...style}}
        repeat="true"
      >
        <View
          style={{
            ...StyleSheet.absoluteFill,
            justifyContent: 'flex-end',
            zIndex: 1
          }}
        >

        { header }

        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end'
          }}
        >
          { children }
        </View>

        </View>
      </Video>
    );
  };

  const title_0 = i18n.language.toLowerCase().startsWith('de') ? (
    <Trans i18nKey={'onboardingFirstPage.title'}>
      Wir lassen auf Worte{'\n'}<TitleHandwrittenRotated>MAHLZEITEN</TitleHandwrittenRotated> <TitleStrikeThrough>Taten</TitleStrikeThrough> folgen
    </Trans>
  ) : (
    <Trans i18nKey={'onboardingFirstPage.title'}>
      We make the world{'\n'}a better <TitleStrikeThrough>place</TitleStrikeThrough> <TitleHandwrittenRotated>PLATE.</TitleHandwrittenRotated>
    </Trans>
  );

  let pages = [];
  if (mode === Mode.NEW_USER) {
    pages = [
      (
        <OnboardingPage
          index={0}
          video={OnboardingFirstPageVideo}
        >
          <View style={{
            padding,
            paddingBottom: bottom + PAGE_INDICATOR_HEIGHT + padding}}
          >
          <Title style={[{color: COLORS.PRIMARY_DARK}, common_styles.centeredText]}>
            { title_0 }
          </Title>

          <VSkipLarge/>

          <Body2 style={[{...LightTheme.general, backgroundColor: null}, common_styles.centeredText]}>
            { t('onboardingFirstPage.body') }
          </Body2>

          <VSkipHuge/>

          <View style={common_styles.buttonContainer}>
            <Button
              style={[common_styles.button, LightTheme.button, {flex: 1}]}
              onPress={() => scroll_ref.current?.scrollTo({x: (active_elem + 1) * win_width, animated})}
            >
              <ButtonText style={LightTheme.button}>
                {t('getStarted')}
              </ButtonText>
            </Button>
          </View>

          <View style={common_styles.buttonContainer}>
            { skip_onboarding_button }
          </View>
          <VSkipMedium/>
          </View>
        </OnboardingPage>
      ),
      (
        <DietPage
          index={1}
          onBack={() => scroll_ref.current?.scrollTo({x: (active_elem - 1) * win_width, animated})}
          onNext={() => scroll_ref.current?.scrollTo({x: (active_elem + 1) * win_width, animated})}
          diet={diet}
          setDiet={setDiet}
        />
      ),
      (
        <GoalsPage
          diet={diet}
          index={2}
          onBack={() => scroll_ref.current?.scrollTo({x: (active_elem - 1) * win_width, animated})}
          onNext={() => scroll_ref.current?.scrollTo({x: (active_elem + 1) * win_width, animated})}
          meal_planning={meal_planning} setMealPlanning={setMealPlanning}
          ingredient_mgmt={ingredient_mgmt} setIngredientMgmt={setIngredientMgmt}
          recipe_mgmt={recipe_mgmt} setRecipeMgmt={setRecipeMgmt}
          recipe_sharing={recipe_sharing} setRecipeSharing={setRecipeSharing}
          recipe_inspo={recipe_inspo} setRecipeInspo={setRecipeInspo}
          food_waste_reduction={food_waste_reduction} setFoodWasteReduction={setFoodWasteReduction}
          eat_less_meat={eat_less_meat} setEatLessMeat={setEatLessMeat}
          eat_less_animal_products={eat_less_animal_products} setEatLessAnimalProducts={setEatLessAnimalProducts}
          seasonal={seasonal} setSeasonal={setSeasonal}
        />
      ),
      (
        <RecipeInspoPage
          diet={diet}
          index={3}
          onBack={() => scroll_ref.current?.scrollTo({x: (active_elem - 1) * win_width, animated})}
          onNext={() => scroll_ref.current?.scrollTo({x: (active_elem + 1) * win_width, animated})}
          cheap_meals={cheap_meals} setCheapMeals={setCheapMeals}
          healthy_meals={healthy_meals} setHealthyMeals={setHealthyMeals}
          meal_prep={meal_prep} setMealPrep={setMealPrep}
          seasonal_recipes={seasonal_recipes} setSeasonalRecipes={setSeasonalRecipes}
          diversity={diversity} setDiversity={setDiversity}
          vegetarian_meals={vegetarian_meals} setVegetarianMeals={setVegetarianMeals}
          vegan_meals={vegan_meals} setVeganMeals={setVeganMeals}
        />
      ),
      (
        <AuthPage
          onBack={() => scroll_ref.current?.scrollTo({x: (active_elem - 1) * win_width, animated})}
          initial_mode={auth_mode}
          onboarding_answers={{
            diet,
            meal_planning,
            ingredient_mgmt,
            recipe_mgmt,
            recipe_sharing,
            recipe_inspo,
            food_waste_reduction,
            eat_less_meat,
            eat_less_animal_products,
            seasonal,
            cheap_meals,
            healthy_meals,
            meal_prep,
            seasonal,
            diversity,
            vegetarian_meals,
            vegan_meals
          }}
          onSignUpSubmit={() => scroll_ref.current?.scrollTo({x: (active_elem + 1) * win_width, animated})}
          onSignUpSuccess={onComplete}
          onSignUpFailure={() => scroll_ref.current?.scrollTo({x: (active_elem - 1) * win_width, animated})}
        />
      ),
      (
        <OnboardingPage
          index={0}
          video={OnboardingLastPageVideo}
        >
          <View style={{height: '25%'}}>
            <LinearGradient/>
          </View>
          <View
            style={{
              ...StyleSheet.absoluteFill,
              padding,
              paddingBottom: bottom + PAGE_INDICATOR_HEIGHT + padding,
              justifyContent: 'flex-end'
            }}
          >
          <Title style={[{...DarkTheme.general, backgroundColor: null}, common_styles.centeredText]}>
            { t('onboardingLastPage.title') }
          </Title>

          <VSkipLarge/>

          <Body2 style={[{...DarkTheme.general, backgroundColor: null}, common_styles.centeredText]}>
            { t('onboardingLastPage.body') }
          </Body2>

          <VSkipMedium/>
          </View>
        </OnboardingPage>
      ),
    ];
  } else {  // EXISTING_USER
    pages = [
      (
        <DietPage
          index={0}
          onBack={() => scroll_ref.current?.scrollTo({x: (active_elem - 1) * win_width, animated})}
          onNext={() => scroll_ref.current?.scrollTo({x: (active_elem + 1) * win_width, animated})}
          diet={diet}
          setDiet={setDiet}
        />
      ),
      (
        <GoalsPage
          diet={diet}
          index={1}
          onBack={() => scroll_ref.current?.scrollTo({x: (active_elem - 1) * win_width, animated})}
          onNext={() => scroll_ref.current?.scrollTo({x: (active_elem + 1) * win_width, animated})}
          meal_planning={meal_planning} setMealPlanning={setMealPlanning}
          ingredient_mgmt={ingredient_mgmt} setIngredientMgmt={setIngredientMgmt}
          recipe_mgmt={recipe_mgmt} setRecipeMgmt={setRecipeMgmt}
          recipe_sharing={recipe_sharing} setRecipeSharing={setRecipeSharing}
          recipe_inspo={recipe_inspo} setRecipeInspo={setRecipeInspo}
          food_waste_reduction={food_waste_reduction} setFoodWasteReduction={setFoodWasteReduction}
          eat_less_meat={eat_less_meat} setEatLessMeat={setEatLessMeat}
          eat_less_animal_products={eat_less_animal_products} setEatLessAnimalProducts={setEatLessAnimalProducts}
          seasonal={seasonal} setSeasonal={setSeasonal}
        />
      ),
      (
        <RecipeInspoPage
          diet={diet}
          index={2}
          onBack={() => scroll_ref.current?.scrollTo({x: (active_elem - 1) * win_width, animated})}
          onNext={() => {
            setDiet(diet);
            postOnboardingAnswers({
              meal_planning,
              ingredient_mgmt,
              recipe_mgmt,
              recipe_sharing,
              recipe_inspo,
              food_waste_reduction,
              eat_less_meat,
              eat_less_animal_products,
              seasonal,
              cheap_meals,
              healthy_meals,
              meal_prep,
              seasonal,
              diversity,
              vegetarian_meals,
              vegan_meals
            });
          }}
          next_button_mode={NEXT_BUTTON_MODE.SUBMIT}
          cheap_meals={cheap_meals} setCheapMeals={setCheapMeals}
          healthy_meals={healthy_meals} setHealthyMeals={setHealthyMeals}
          meal_prep={meal_prep} setMealPrep={setMealPrep}
          seasonal_recipes={seasonal_recipes} setSeasonalRecipes={setSeasonalRecipes}
          diversity={diversity} setDiversity={setDiversity}
          vegetarian_meals={vegetarian_meals} setVegetarianMeals={setVegetarianMeals}
          vegan_meals={vegan_meals} setVeganMeals={setVeganMeals}
        />
      )
    ];
  }

  console.assert(pages.length === num_pages, "'num_pages' does not match length of 'pages'");

  return (
    <Modal
      visible={screen_is_focused && visible}
    >
      <ScrollView
        ref={scroll_ref}
        //disableIntervalMomentum={true}
        showsHorizontalScrollIndicator={false}
        horizontal
        decelerationRate="fast"
        snapToInterval={win_width}
        snapToAlignment="center"
        onScroll={event => setActiveElem(Math.round(event.nativeEvent.contentOffset.x / event.nativeEvent.contentSize.width * pages.length))}
        scrollEventThrottle={16}
        scrollEnabled={false}
        style={StyleSheet.absoluteFill}
      >
        {
          pages.map((page, index) => (
            <View
              style={{
                height: '100%',
                width: win_width,
                flexDirection: 'row',
                justifyContent: 'center'
              }}
              key={index}
            >
              <View
                style={[
                  theme.general,
                  {
                    width,
                    justifyContent: 'center',
                  }
                ]}
              >
                { page }
              </View>
            </View>
          ))
        }
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          width: win_width,
          alignItems: 'center',
          justifyContent: 'flex-end',
          bottom
        }}
      >
        <PageIndicator
          pages={pages}
          color={(active_elem === 0) ? LightTheme.general.color : theme.general.color}
          active_index={active_elem}
        />
      </View>
    </Modal>
  );
}

export default Onboarding;
