import React from 'react';

import Svg, { G, Path, Rect } from 'react-native-svg';

const Fridge = ({color = 'white', filled = false}) => {
  if (filled) {
    return (
      <Svg width="100%" height="100%" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path fillRule="evenodd" clipRule="evenodd" d="M8.5 5C8.5 3.89543 9.39543 3 10.5 3H22.5C23.6046 3 24.5 3.89543 24.5 5V12V13.5V25.5V27V29.5H22V28H11V29.5H8.5V27V25.5V13.5V12V5ZM12.5 8.25C12.5 7.55964 13.0596 7 13.75 7C14.4404 7 15 7.55964 15 8.25C15 8.94036 14.4404 9.5 13.75 9.5C13.0596 9.5 12.5 8.94036 12.5 8.25ZM13.75 15C13.0596 15 12.5 15.5596 12.5 16.25V18.25C12.5 18.9404 13.0596 19.5 13.75 19.5C14.4404 19.5 15 18.9404 15 18.25V16.25C15 15.5596 14.4404 15 13.75 15Z" style={{fillRule: 'evenodd', fill: color}}/>
      </Svg>
    );
  } else {
    return (
      <Svg className="Fridge" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" aria-labelledby="title" style={{fillRule: 'evenodd'}}>
        <Path fillRule="evenodd" clipRule="evenodd" d="M11 11V5.5H22V11H11ZM8.5 12V11V5C8.5 3.89543 9.39543 3 10.5 3H22.5C23.6046 3 24.5 3.89543 24.5 5V11V12V13.5V25.5V27V29.5H22V28H11V29.5H8.5V27V25.5V13.5V12ZM22 25.5V13.5H11V25.5H22ZM12.5 16.25C12.5 15.5596 13.0596 15 13.75 15C14.4404 15 15 15.5596 15 16.25V18.25C15 18.9404 14.4404 19.5 13.75 19.5C13.0596 19.5 12.5 18.9404 12.5 18.25V16.25ZM13.75 7C13.0596 7 12.5 7.55964 12.5 8.25C12.5 8.94036 13.0596 9.5 13.75 9.5C14.4404 9.5 15 8.94036 15 8.25C15 7.55964 14.4404 7 13.75 7Z" style={{fill: color}}/>
      </Svg>
    );
  }
}

export default Fridge;
