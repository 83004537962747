import React from 'react'
import {
  FlatList,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Button from '../../common/components/Button';
import common_styles, { fullWidth } from '../../common/components/CommonStyles';
import { TitleWClose } from '../../header/components/Header';

import { ButtonText, useAppDimensions } from '../../utils/';
import { useTheme } from '../../utils/Theme';

import Checkmark from '../../assets/Checkmark';

export const Picker = ({
  title_string,
  options,
  visible,
  onRequestClose,
  close_on_pick = true
}) => {
  const { height, width } = useAppDimensions();

  const theme = useTheme();

  const { bottom, top } = useSafeAreaInsets();

  return (
    <Modal
      animationType={Platform.OS === "web" ? "none" : "fade"}
      visible={visible}
      onRequestClose={onRequestClose}
      transparent
    >
      <View style={common_styles.modalContainer}>
        <Pressable style={common_styles.modalBackground} onPress={onRequestClose}/>
        <View
          style={[
            common_styles.modal,
            theme.general,
            {
              width,
              maxHeight: height,
              position: 'absolute',
              bottom: 0,
              paddingBottom: bottom,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }
          ]}
        >
          <TitleWClose
            title_string={title_string}
            close={onRequestClose}
          />
          <FlatList
            style={{width: '100%'}}
            data={options}
            renderItem={({item, index}) => (
              <Button
                key={index}
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  opacity: item.disabled ? 0.5 : 1.0,
                  paddingHorizontal: 0
                }}
                onPress={() => {
                  if (!item.disabled) {
                    if (close_on_pick) onRequestClose();
                    item.action();
                  }
                }}
              >
                <ButtonText style={[theme.general, item.style]}>
                  { item.label }
                </ButtonText>
              </Button>
            )}
            ItemSeparatorComponent={() => (<View style={{width: '100%', height: StyleSheet.hairlineWidth, ...theme.button}}/>)}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </View>
    </Modal>
  );
}

const ValuePicker = ({
  title_string,
  values,
  value,
  setValue,
  visible,
  onRequestClose,
  i18n_prefix
}) => {
  const { height, width } = useAppDimensions();

  const { t } = useTranslation('common');
  const theme = useTheme();

  const { bottom } = useSafeAreaInsets();

  return (
    <Modal
      animationType={Platform.OS === "web" ? "none" : "fade"}
      visible={visible}
      onRequestClose={onRequestClose}
      transparent
    >
      <View style={common_styles.modalContainer}>
        <Pressable style={common_styles.modalBackground} onPress={onRequestClose}/>
        <View
          style={[
            common_styles.modal,
            theme.general,
            {
              width,
              maxHeight: height,
              position: 'absolute',
              bottom: 0,
              paddingBottom: bottom,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }
          ]}
        >
          <TitleWClose
            title_string={title_string}
            close={onRequestClose}
          />
          <FlatList
            style={{width: '100%'}}
            data={values}
            renderItem={({item, index}) => (
              <Button
                key={index}
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  paddingHorizontal: 0
                }}
                onPress={() => {
                  setValue(item);
                  onRequestClose();
                }}
              >
                <ButtonText style={theme.general}
                >
                  { t(`${i18n_prefix}.${item}`) }
                </ButtonText>
                <View style={{width: 25, height: 25}}>
                  {
                    (value === item) ? <Checkmark color={theme.general.color}/> : null
                  }
                </View>
              </Button>
            )}
            ItemSeparatorComponent={() => (<View style={{width: '100%', height: StyleSheet.hairlineWidth, ...theme.button}}/>)}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </View>
    </Modal>
  );
}

export default ValuePicker;
